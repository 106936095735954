import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/faq.css";

const PRIVACYPage = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/"); // Navigate back to the home page
  };

  return (
    <div className="faq-container">
      <header className="faq-header">
        <button className="back-button" onClick={handleBackClick}>
          ← Back
        </button>
        <div className="logo-container">
          <a href="/">
            <img
              src="./eblogo_2.png"
              alt="ExpenseBot Logo"
              className="faq-logo"
            />
          </a>
        </div>
        <div className="faq-title-container">
          <h1 className="faq-title">Privacy Policy</h1>
        </div>
      </header>

      <div className="faq-item">
        <p className="faq-question">1. Permissions We Require</p>
        <p className="faq-answer">
          ExpenseBot requires the following permissions from your Google Account
          to function effectively:
          <br />
          <strong>Google Photos (Read and Add):</strong> We access your Google
          Photos to scan for receipts using AI and can save receipt photos you
          upload directly to your Google Photos library for easier organization.
          <br />
          <strong>Google Drive (Limited Access):</strong> We create and manage a
          folder for receipt photos and a Google Sheets spreadsheet to help
          organize your expenses.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">2. How We Use Your Data</p>
        <p className="faq-answer">
          We use your data solely to provide our service. Our AI processes your
          receipt photos to extract information. Receipt images are temporarily
          uploaded to our servers for processing, after which they are
          immediately deleted. All photos and spreadsheets remain securely
          stored in your Google Drive under your control.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">3. Data Storage and Security</p>
        <p className="faq-answer">
          ExpenseBot does not store your photos or expense data on our servers
          permanently. Your photos and Google Sheets spreadsheet remain securely
          stored in your Google Drive, where you have full control. We ensure
          secure, encrypted transmission of your data during processing.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">4. Information We Collect</p>
        <p className="faq-answer">
          ExpenseBot collects and stores minimal information necessary to
          provide and improve our service. Specifically, we store:
          <br />
          <ul>
            <li>
              Your name, email address, and profile image link from your Google
              account.
            </li>
            <li>
              The ID of the Google Sheets spreadsheet we create for you to
              organize your expenses.
            </li>
            <li>
              The ID of the Google Drive folder we create to store your receipt
              photos.
            </li>
            <li>
              Authentication tokens to enable secure interaction with your
              Google account.
            </li>
            <li>
              Your Stripe subscription ID and customer ID (if applicable), for
              managing subscriptions.
            </li>
          </ul>
          <br />
          We do not store any photos, expense information, or details of your
          receipts. These are stored securely in your Google Drive under your
          control.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">
          5. Data Sharing, Disclosure, and Transfer
        </p>
        <p className="faq-answer">
          We do not share or sell your personal information to third parties for
          marketing purposes. However, we may share or disclose your data under
          the following circumstances:
          <br />
          <strong>With Google Services:</strong> We share data only with Google
          services (such as Google Photos, Google Drive, Google Gemini, and
          Google Vision) as part of the functionality of the ExpenseBot service.
          <br />
          <strong>For Legal Reasons:</strong> We may share your information to
          comply with legal obligations, such as in response to a legal request
          or to enforce our legal rights.
          <br />
          <strong>Business Transfers:</strong> If ExpenseBot is involved in a
          merger, acquisition, or sale of assets, the minimal data we collect
          (such as your email, profile information, and subscription data) may
          be transferred as part of that transaction to ensure continuity of
          service.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">6. Data Deletion and Reset</p>
        <p className="faq-answer">
          You can reset your account at any time. This will delete all data
          created by ExpenseBot in your Google Drive, including photos and
          spreadsheets, while preserving your trial or subscription status. This
          allows you to start over without losing your account status.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">7. Third-Party Services</p>
        <p className="faq-answer">
          ExpenseBot integrates with third-party services like Google Photos,
          Google Drive, which are governed by their own privacy policies. We
          also use Google Vision and Google Gemini to analyze and process
          receipt photos as part of our service.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">8. Contact Information</p>
        <p className="faq-answer">
          If you have any questions or concerns about this privacy policy,
          please contact us at info@expensebot.ai.
        </p>
      </div>
      <div className="faq-item">
        <p className="faq-question">9. Google Workspace API Compliance</p>
        <p className="faq-answer">
          ExpenseBot does not use data obtained from Google Workspace APIs to
          develop, improve, or train generalized AI or machine-learning models.
          All data from Google Workspace APIs, including Google Photos and
          Google Drive, is used solely to provide and support our service to
          you. We fully comply with Google’s “Limitation on User Data Transfer”
          policy, which prohibits the use of Workspace user data to train
          non-personalized AI or ML models.
        </p>
      </div>
    </div>
  );
};

export default PRIVACYPage;
