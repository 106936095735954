// CameraSelector.js
import React, { useState, useRef, useEffect, useMemo } from "react";
import { uploadToGooglePhotos } from "./cameraPhotosPicker";
import "../styles/CameraSelector.css";

const CameraSelector = ({ onClose, onPhotosSelected, onError }) => {
  const [status, setStatus] = useState("initializing");
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const fileInputRef = useRef(null);

  // Device detection
  const getDeviceSupport = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const iOSVersion = isIOS
      ? parseInt(navigator.userAgent.match(/ OS (\d+)_/)?.[1] || "0", 10)
      : 0;
    const hasGetUserMedia = !!navigator.mediaDevices?.getUserMedia;

    return {
      useCamera: hasGetUserMedia && (!isIOS || iOSVersion >= 14),
      useCapture: isIOS, // For file input capture attribute
    };
  };

  const deviceSupport = useMemo(() => getDeviceSupport(), []);

  useEffect(() => {
    const initCamera = async () => {
      if (!deviceSupport.useCamera) {
        setStatus("fallback");
        return;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" },
        });
        streamRef.current = stream;
        videoRef.current.srcObject = stream;
        setStatus("ready");
      } catch (error) {
        console.log("Camera error, falling back to file input:", error);
        setStatus("fallback");
      }
    };

    initCamera();

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, [deviceSupport.useCamera, onClose, onError]);

  const handleCapture = async () => {
    try {
      setStatus("processing");

      // Create canvas and get photo data
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      canvas.getContext("2d").drawImage(videoRef.current, 0, 0);

      const blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/jpeg", 0.8)
      );

      // Upload to Google Photos
      const mediaItem = await uploadToGooglePhotos(blob);

      // Format like your existing flow expects
      const photoData = [
        {
          id: mediaItem.id,
          baseUrl: mediaItem.baseUrl,
          mimeType: "image/jpeg",
          filename: mediaItem.filename,
          mediaMetadata: {
            creationTime: new Date().toISOString(),
          },
        },
      ];

      onPhotosSelected(photoData);
      onClose();
    } catch (error) {
      console.error("Capture error:", error);
      onError?.(error.message);
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setStatus("processing");

      // Convert File to Blob if needed
      const blob =
        file.type === "image/jpeg"
          ? file
          : await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                  const canvas = document.createElement("canvas");
                  canvas.width = img.width;
                  canvas.height = img.height;
                  const ctx = canvas.getContext("2d");
                  ctx.drawImage(img, 0, 0);
                  canvas.toBlob(resolve, "image/jpeg", 0.8);
                };
                img.src = e.target.result;
              };
              reader.readAsDataURL(file);
            });

      // Use same upload flow as camera
      const mediaItem = await uploadToGooglePhotos(blob);
      const photoData = [
        {
          id: mediaItem.id,
          baseUrl: mediaItem.baseUrl,
          mimeType: "image/jpeg",
          filename: mediaItem.filename,
          mediaMetadata: {
            creationTime: new Date().toISOString(),
          },
        },
      ];

      onPhotosSelected(photoData);
      onClose();
    } catch (error) {
      console.error("File upload error:", error);
      onError?.(error.message);
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content camera-selector">
        <button onClick={onClose} className="close-xc">
          ×
        </button>

        {status === "fallback" ? (
          <div className="file-input-container">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              {...(deviceSupport.useCapture ? { capture: "environment" } : {})}
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
            <button
              className="capture-button"
              onClick={() => fileInputRef.current?.click()}
            >
              <i className="fas fa-camera"></i>
            </button>
          </div>
        ) : (
          <div className="camera-container">
            <video
              ref={videoRef}
              autoPlay
              playsInline
              className="camera-preview"
            />

            {status === "ready" && (
              <button onClick={handleCapture} className="capture-button">
                <i className="fas fa-camera"></i>
              </button>
            )}
          </div>
        )}

        {status === "processing" && (
          <div className="processing-status">
            <i className="fas fa-spinner fa-spin"></i>
            <span>Processing photo...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CameraSelector;
