// Upload photo to Google Photos after capture
const uploadToGooglePhotos = async (photoBlob) => {
  // Convert blob to base64
  const base64Data = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(photoBlob);
  });

  const response = await fetch("/api/upload-camera-photo", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ photoData: base64Data }),
  });

  if (!response.ok) {
    throw new Error("Failed to upload photo");
  }

  const { mediaItem } = await response.json();
  return mediaItem;
};

export { uploadToGooglePhotos };
